import React, { useEffect  } from 'react';

const AdSense = ({ adClient, adSlot, adFormat, layoutKey="", adLayout="" }) => {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
            console.log(`error getting google ad: ${e}`)
        }
    },[]);

    return (
        <>
            <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={adClient}
                data-ad-slot={adSlot}
                data-ad-format={adFormat}
                data-full-width-responsive="true"
                data-ad-layout-key={layoutKey}
                data-ad-layout={adLayout}>
            </ins>
        </>
    );
};

export default AdSense