import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';

import API_KEY from '../config';
import { Helmet } from 'react-helmet';
import AdSense from './AdSense';

import 'react-tooltip/dist/react-tooltip.css';
import '../styles/topicDetailsStyle';

const SubtopicTutorial = () => {
    const { topic_subtopic } = useParams();
    const [tutorialContent, setTutorialContent] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //console.log(`<<<<<<<<<<<<<< Calling getPageData with Sub Topic: ${topic_subtopic}`);
        var p = topic_subtopic.split('_')  // TODO
        fetchSubtopicTutorial(p[1]); // TODO needs to send both, topic and subtopic. api needs to change first
    }, [topic_subtopic]);

    useEffect(() => {
        // Select all <pre> tags and check for nested <code> tags
        const preTags = document.querySelectorAll('pre');
        
        preTags.forEach(preTag => {
            const codeTag = preTag.querySelector('code');
            
            preTag.style.backgroundColor = '#494949';  // Example: change background color
            preTag.style.padding = '10px';             // Example: add padding
            preTag.style.color = 'white';

            if (codeTag) {
                // Add inline style to the <code> tag
                codeTag.style.backgroundColor = '#494949';
                codeTag.style.color = 'white';           // Example: change text color
                codeTag.style.fontSize = '1rem';           // Example: change font size
            }
        });
    }, [tutorialContent]); // Only run this effect when the HTML changes

    const fetchSubtopicTutorial = async (topic_subtopic) => {
        setLoading(true);
        try {
            //console.log(`getting subtopic tutorial for subtopic: ${topic_subtopic}`);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/tutorials/${encodeURIComponent(topic_subtopic)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            }); 
            const data = await response.json();
            setTutorialContent((await data.content));
        } catch (error) {
            console.error('Error fetching subtopic tutorial:', error);
        } finally {
            setLoading(false);
        }
    };
      
    const RenderHtmlContent = ({ htmlString }) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);        
        return (
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    };
      
    return (
        <div style={styles.panel}>
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div style={styles.container}>
                        <br />
                        <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                        <AdSense adClient="ca-pub-6060537356298204" adSlot="2618836852" adFormat="fluid" layoutKey="-fb+5w+4e-db+86" />
                        
                        <div>
                            <RenderHtmlContent htmlString={tutorialContent} />
                        </div>

                        <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                        <AdSense adClient="ca-pub-6060537356298204" adSlot="5922854719" adFormat="auto" />
                    </div>
                </>
            )}
        </div>
    );
};

const styles = {
    panel: {
        flex: 1,
        backgroundColor: '#FFF',
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    container: {
        marginTop: '30px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        fontFamily: 'Arial, sans-serif'
    }
}

export default SubtopicTutorial;
