import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="container">
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            <header>
                <h1 style={{marginTop: '0px'}}>Terms of Service</h1>
            </header>
            <main>
                <section style={{marginBottom: '2em'}}>
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to Cheatsheet++. By accessing or using our website, you agree to adhere to the following terms and conditions. 
                        Please review these terms carefully to ensure you understand and agree with them. If you disagree with any part of these terms, 
                        you should discontinue use of the site immediately. Your continued use of the site will be considered acceptance of these terms.
                    </p>
                    <p>
                        These terms apply to all visitors, users, and others who access or use the site. We may update these terms from time to time, and 
                        any changes will be effective immediately upon posting on this page. We encourage you to review this page periodically to stay informed.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>2. Intellectual Property</h2>
                    <p>
                        The content on this site, including text, graphics, logos, images, and software, is the property of Cheatsheet++ or its content suppliers 
                        and is protected by international copyright laws. Unauthorized use of any content may result in legal action against you. 
                        You may not reproduce, distribute, or create derivative works from any content on this site without prior written consent.
                    </p>
                    <p>
                        All trademarks, service marks, and trade names used on this site are the property of Cheatsheet++ or their respective owners. 
                        The use of these trademarks without permission is prohibited. If you have any questions about our intellectual property rights, please contact us.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>3. Use of Site</h2>
                    <p>
                        You may use this site for lawful purposes only. You agree not to engage in any activity that could harm the site, 
                        its users, or any third parties. This includes, but is not limited to, attempts to gain unauthorized access to the site, 
                        transmitting harmful or malicious code, or disrupting the normal functioning of the site.
                    </p>
                    <p>
                        We reserve the right to suspend or terminate your access to the site if we believe you have violated these terms. 
                        Any such suspension or termination will be at our sole discretion and may be without prior notice.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>4. Limitation of Liability</h2>
                    <p>
                        Cheatsheet++ shall not be liable for any damages arising from the use of, or inability to use, the materials on this site or the performance of the products. 
                        This includes, but is not limited to, direct, indirect, incidental, punitive, or consequential damages. 
                        We do not guarantee the accuracy or completeness of the materials on this site and disclaim all warranties, whether express or implied.
                    </p>
                    <p>
                        If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect. 
                        Your use of this site is at your own risk, and you are solely responsible for any damage that may occur to your computer system or other devices as a result of using this site.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>5. Changes to Terms of Service</h2>
                    <p>
                        Cheatsheet++ reserves the right to modify these terms at any time without prior notice. Changes to the terms will be posted on this page, 
                        and your continued use of the site constitutes your acceptance of the updated terms. We encourage you to review these terms periodically to stay informed of any changes.
                    </p>
                    <p>
                        If you do not agree to any changes, you must stop using the site. Your use of the site after any changes have been posted constitutes your acceptance of the revised terms.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>6. Contact Information</h2>
                    <p>
                        If you have any questions or concerns about these Terms of Service, please reach out to us at <a href="mailto:support@cheatsheet-plus-plus.com">support@cheatsheet-plus-plus.com</a>. 
                        We are here to assist you and address any issues you may have regarding these terms or your use of the site.
                    </p>
                    <p>
                        We will do our best to respond to your inquiries in a timely manner. Your feedback is important to us and helps us improve our services and user experience.
                    </p>
                </section>
            </main>
        </div>
    );
};

export default TermsOfService;
