import React, { useEffect, useState } from 'react'
import API_KEY from '../config';
import '../styles/Books.css';

const Books = ({ topic, index }) => {
    const [booksData, setBooksData] = useState([])

    useEffect(() => {
        const getBooksData = async () => {
            let data = await fetchBooks(topic)

            //console.log('index:', index)
            if (index || index === 0)
                data = data.slice(index, index + 1)

            //console.log('data', data)

            setBooksData(data);
        }

        getBooksData()  
    }, [])

    const fetchBooks = async (topic) => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/books/${encodeURIComponent(topic)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${API_KEY}`
            }
        });
          
        return await response.json();
    }

    return (
        <>
            {booksData?.length > 0 &&
                <div>
                    <h2>Books</h2>
                    <h4 style={{ fontStyle: 'italic', marginTop: '5px', fontSize: 'smaller' }}>
                        Purchases made through our Amazon affiliate book links help support this website. Thank you for your support!
                    </h4>
                    <div className="image-links-container">
                        {booksData.map((item, index) => (
                            <div key={index} className="image-link-item">
                                <a href={item.amazonLink} target="_blank" rel="noopener noreferrer">
                                    <img 
                                        src={item.imageUrl} 
                                        alt={`Link ${index + 1}`} 
                                        className="image-link" 
                                    />
                                </a>
                                <p className="description">{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <hr/>
                </div>
            }
        </>
    );
}

export default Books