import React from 'react';
import { Helmet } from 'react-helmet';
import AdSense from '../AdSense';
import '../../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="container">
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            <header>
                <h1>Privacy Policy</h1>
            </header>
            <main>
                <section style={{marginBottom: '2em'}}>
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to cheatsheet-plus-plus.com ("cheatsheet++"). Your privacy is important to us, and we are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, store, and protect your data when you interact with our website. By accessing or using our website, you agree to the practices described in this policy.
                    </p>
                    <p>
                        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We encourage you to review this policy regularly to stay informed about how we are protecting your information. If you do not agree with the terms of this Privacy Policy, please discontinue use of our website.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>2. Information We Collect</h2>
                    <p>
                        When you sign up for our services or interact with our website, we collect certain personal information from you. This information includes:
                    </p>
                    <ul>
                        <li><strong>Name:</strong> To identify and address you personally.</li>
                        <li><strong>Email address:</strong> To communicate with you, including sending updates, newsletters, or promotional materials.</li>
                        <li><strong>IP address:</strong> To analyze and monitor site usage and to ensure the security of our website.</li>
                    </ul>
                    <p>
                        We may also collect other information that you voluntarily provide to us through contact forms or other interactions on the site. This additional information helps us better understand your needs and improve our services.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>3. How We Use Your Information</h2>
                    <p>
                        The information we collect is used to enhance your experience on our website and to provide you with a more personalized service. Specifically, we use your information for the following purposes:
                    </p>
                    <ul>
                        <li><strong>To improve our services:</strong> By analyzing user feedback and usage patterns, we can make enhancements to our website and services.</li>
                        <li><strong>For marketing purposes:</strong> We may use your email address to send you newsletters, updates, or special offers related to our services. You can opt out of receiving marketing communications at any time.</li>
                        <li><strong>To personalize your user experience:</strong> Your information helps us tailor content and recommendations to better suit your preferences.</li>
                    </ul>
                    <p>
                        We do not share your personal information with third parties, except as necessary to provide our services or as required by law. We are committed to maintaining the confidentiality and security of your data.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>4. Data Storage and Security</h2>
                    <p>
                        We take the security of your personal data seriously. Your email and password are stored in an encrypted format in our secure database. We employ industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
                    </p>
                    <p>
                        Your data is retained indefinitely unless you request its deletion. If you choose to delete your account or request removal of your data, we will process your request promptly and in accordance with applicable laws.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>5. User Rights</h2>
                    <p>
                        As a user, you have the right to access and correct your personal data. This includes the ability to update or remove information that is inaccurate or incomplete. If you wish to exercise these rights, please contact us at <a href="mailto:privacy@cheatsheet-plus-plus.com">privacy@cheatsheet-plus-plus.com</a>.
                    </p>
                    <p>
                        We will respond to your request within a reasonable timeframe and in accordance with applicable data protection laws. If you have any concerns about how we handle your data, you may also contact us to discuss your concerns or to seek further information.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>6. Third-Party Services</h2>
                    <p>
                        We use various third-party services to help us operate and improve our website. This includes analytics providers and advertising networks. While these services assist us in delivering and optimizing our content, they do not have access to your personal data. We ensure that any third parties we work with comply with our privacy standards and are contractually obligated to protect your information.
                    </p>
                    <p>
                        We do not share your personal data with these third parties except as necessary for the services they provide. We encourage you to review the privacy policies of any third-party services you interact with through our website.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>7. Cookies</h2>
                    <p>
                        To enhance your experience on our website, we use cookies, including essential and analytics cookies. Essential cookies are necessary for the proper functioning of the site, while analytics cookies help us understand how users interact with the site so we can make improvements.
                    </p>
                    <p>
                        You can control or disable cookies through your browser settings. However, please note that disabling cookies may affect your ability to use certain features of our website. For more information on how to manage cookies, please refer to your browser's help section or visit [a relevant online resource].
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>8. Contact Information</h2>
                    <p>
                        If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at <a href="mailto:privacy@cheatsheet-plus-plus.com">privacy@cheatsheet-plus-plus.com</a>. We are committed to addressing your inquiries and ensuring that your privacy is protected.
                    </p>
                    <p>
                        Your feedback is valuable to us and helps us improve our privacy practices. We aim to respond to all inquiries in a timely manner and provide any assistance you may need regarding your personal information.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>9. Changes to This Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any modifications will be posted on this page, and the effective date will be updated accordingly.
                    </p>
                    <p>
                        We will notify you of significant changes to the policy via email or through a prominent notice on our website. We encourage you to review this policy periodically to stay informed of any updates and to understand how we are protecting your information.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>10. Jurisdiction</h2>
                    <p>
                        This Privacy Policy is governed by and construed in accordance with the laws of South Florida. Any disputes arising under or related to this Privacy Policy shall be subject to the jurisdiction of the courts located in South Florida.
                    </p>
                    <p>
                        By using our website, you consent to the jurisdiction and venue of these courts. If any provision of this policy is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect.
                    </p>
                </section>
            </main>
        </div>
    );
};

export default PrivacyPolicy;
