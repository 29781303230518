import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlay } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/Home.css';

const HomePage = () => {
    return (
        <div
            style={{
                backgroundImage: 'url(/home.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
                color: 'white',
            }}
        >
            <Helmet>
                <title>Cheatsheet++ | 600+ Software Development Cheat Sheets & Tutorials</title>
                <meta name="description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning. Join now and start improving your coding skills!" />
                <meta name="keywords" content="cheat sheets, software development, coding tutorials, programming, learning, developer resources, free educational content" />
                <meta property="og:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta property="og:description" content="Discover a comprehensive collection of cheat sheets and tutorials for software developers. Enhance your coding skills with Cheatsheet++." />
                <meta property="og:image" content="/og-image.jpg" />
                <meta property="og:url" content="https://www.cheatsheet-plus-plus.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Cheatsheet++ | Free Software Development Cheat Sheets & Tutorials" />
                <meta name="twitter:description" content="Cheatsheet++ offers a vast collection of free software development cheat sheets, tutorials, and historical context to enhance your learning." />
                <meta name="twitter:image" content="/twitter-image.jpg" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            <div className="container">
                <header style={{ textAlign: 'center', padding: '0px', backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
                    <h1 style={{ color: '#003860', marginTop: '0px' }}>Your Ultimate Resource for Comprehensive Cheat Sheets and Tutorials</h1>
                    <p style={{ fontSize: 'larger' }}>
                        Access over 600 cheat sheets and 18,000 tutorials. Enhance your software development skills with our free and regularly updated resources.
                    </p>
                </header>
                <main>
                    <section className="value-proposition">
                        <h2 style={{ color: '#003860', margin: '0px' }}>Why Choose Cheatsheet++?</h2>
                        <p style={{ textAlign: 'left', fontSize: 'larger' }}>
                            Cheatsheet++ is dedicated to providing <strong>FREE</strong> educational content for software developers. Our searchable cheat sheets, in-depth tutorials, and historical insights make learning more effective and efficient.
                        </p>
                        <Link to="/topics">
                            <button className="btn btn-primary" aria-label="Start Learning Now">
                                Start Learning Now - 600+ Resources and Growing Daily!
                                <FontAwesomeIcon icon={faPlay} style={{ marginLeft: '10px' }} />
                            </button>
                        </Link>
                    </section>
                    <section className="stats">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card" style={{ height: '360px' }}>
                                    <img src="../cheatsheet.jpg" className="card-img-top" alt="Cheat Sheets and Subtopics" />
                                    <div className="card-body">
                                        <h3 className="card-title">600+ Cheat Sheets</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ height: '360px' }}>
                                    <h3 className="card-title">Benefits of Cheat Sheets</h3>
                                    <ul style={{ fontSize: 'smaller', textAlign: 'left' }}>
                                        <li>Condensed Information</li>
                                        <li>Quick Reference</li>
                                        <li>Enhanced Retention</li>
                                        <li>Time Efficiency</li>
                                        <li>Focus on Key Points</li>
                                        <li>Reduced Cognitive Load</li>
                                        <li>Improved Organization</li>
                                        <li>Boosts Confidence</li>
                                        <li>Encourages Active Learning</li>
                                        <li>Versatile Learning Tool</li>
                                        <li>Supports Exam Preparation</li>
                                        <li>Encourages Regular Review</li>
                                        <li>Enhances Problem-Solving Skills</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ height: '360px' }}>
                                    <img src="../tutorial.jpg" className="card-img-top" alt="Tutorials" />
                                    <div className="card-body">
                                        <h3 className="card-title">18,000+ Tutorials</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="coming-soon">
                        <h2>Coming Soon</h2>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Flash Cards</h3>
                                        <p className="card-text">Cross-Topic Flash Cards for Better Learning</p>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                                        <a href="mailto:suggestions-fc@cheatsheet-plus-plus.com" style={{ fontSize: 'smaller' }}>Send us your suggestions to prioritize this feature</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">Interview Q&A</h3>
                                        <p className="card-text">Prepare with Common Interview Questions and Answers</p>
                                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
                                        <a href="mailto:suggestions-QA@cheatsheet-plus-plus.com" style={{ fontSize: 'smaller' }}>Send us your suggestions to prioritize this feature</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default HomePage;
