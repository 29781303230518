import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes, FaArrowLeft } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import logo5 from './images/logo5.png';
import './Navigation.css';

const Navigation = ({user}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef();
  const location = useLocation();
  const navigate = useNavigate(); 

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };

    //console.log("user: ", user)

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Determine if the current path is the Topics page
  const isOnTopicsPage = location.pathname === '/topics';
  const isOnHomePage = location.pathname === '/';
  const isOnLoginPage = location.pathname === '/login'

  return (
    <>
      <header id="navbar" style={{ position: 'fixed', width: '100%', top: '0px', backgroundColor: '#333', zIndex: 1000 }}>
        <nav className="navbar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* Back Button */}
          {!isOnHomePage && (
              <Button
              onClick={handleBackClick}
              style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: 'black', background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <FaArrowLeft size="20" color="white"/>
            </Button>
          )}
          
          <Link to="/" className="home-link">
            <img src={logo5} className="App-logo" alt="logo" style={{ height: '45px' }} />
            {/* <h1 className="logo-text">
              cheatsheet++
            </h1> */}
          </Link>

          {/* show login name or login button */}
          {/* {
            user ? <span style={{color: 'white', fontSize: 'smaller'}}>Alex</span> : 
            (!isOnLoginPage && <Button className="btn btn-sm p5 btn-warning" style={{backgroundColor: '#B54800', color: 'white'}}>log in</Button>)
          }  */}

          {!isOnTopicsPage && (
            <Link to="/topics" style={{ display: 'block', padding: '10px', textAlign: 'center', color: '#eee' }}>cheatsheets</Link>
          )}
          
          <div className="menu-toggle" onClick={toggleNav} style={{ cursor: 'pointer' }}>
            {isNavOpen ? <FaTimes size="24" color="white" /> : <FaBars size="24" color="white" />}
          </div>
          <div
            ref={navRef}
            className={`mobile-nav ${isNavOpen ? 'open' : ''}`}
            style={{ 
              position: 'absolute', 
              top: '60px', 
              right: '20px', 
              backgroundColor: '#fff', 
              color: 'black',
              width: '180px', 
              display: isNavOpen ? 'block' : 'none', 
              borderRadius: '5px',
              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.7)',
              //border: 'solid',
              //borderColor: 'lightGrey',
              zIndex: 1000
            }}
          >
            <Link to="/topics" style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: '#333' }}>600+ cheatsheets</Link>

            <Link to="/home" style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: '#333' }}>home</Link>
            
            <Link to="/about" style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: '#333' }}>about us</Link>
            
            {/* {
              user ? <Link to="/" style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: '#333' }}>Log out</Link> :
                      <Link to="/login" style={{ display: 'block', padding: '10px', textAlign: 'center', textDecoration: 'none', color: '#333' }}>log in</Link>
            } */}
          </div>
        </nav>      
      </header>

      
    </>
    
    
  );
};

export default Navigation;
