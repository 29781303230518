export const styles = {
    panel: {
        flex: 1,
        backgroundColor: '#FFF',
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    container: {
        marginTop: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        fontFamily: 'Arial, sans-serif'
    },
    header: {
        // backgroundColor: '#005491',
        padding: '10px',
        color: 'white',
        textAlign: 'center',
        marginTop: '0px'
    },
    headerContent: {
        marginTop: '0px',
        display: 'flex',
        justifyContent: 'center', // Center the content horizontally
    },
    headerTitleContainer: {
        display: 'flex',
        alignItems: 'center', // Center items vertically
        justifyContent: 'space-between', // Space between title and icon
        width: '100%', // Ensure the container takes full width
    },
    headerTitle: {
        marginTop: '0px',
        flex: 1, // Allow the title to take up remaining space
        textAlign: 'left', // Center the title text
    },
    historyIcon: {
        marginRight: '5px',
        cursor: 'pointer',
        marginLeft: '20px',
    },
    main: {
        //maxWidth: '1200px',
        margin: '20px auto',
        marginTop: '0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    columns: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px'
    },
    column: {
        flex: '1 1 30%', // Adjust the percentage as needed to ensure three columns
        minWidth: '350px', // Ensure a minimum width for columns
        boxSizing: 'border-box'
    },
    section: {
        backgroundColor: '#EFEFEF',
        paddingRight: '20px',
        paddingLeft: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 2px rgba(0, 0, 0, 0.9)'
    },
    sectionTitle: {
        fontSize: '28px',
        marginTop: '10px',
        paddingTop: '10px',
        textAlign: 'center',
        fontWeight: '50'
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    listItem: {
        marginTop: '30px',
        marginBottom: '20px',
        fontSize: '12px',
    },
    subtopicHeader: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
        //justifyContent: 'space-between'
    },
    subtopicTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        margin: '0 10px 0 0',
    },
    codeBlockBeginner: {
        backgroundColor: '#FFF2C4',
        padding: '10px',
        paddingTop: '0px',
        margin: '0px',
        borderRadius: '5px',
        whiteSpace: 'pre-wrap',
        border: '1px solid gray',
        fontSize: '12px',
    },
    codeBlockIntermediate: {
        backgroundColor: '#CBD6D0',
        padding: '10px',
        paddingTop: '0px',
        borderRadius: '5px',
        whiteSpace: 'pre-wrap',
        border: '1px solid gray',
        color: '#000',
        fontSize: '12px',
    },
    codeBlockAdvanced: {
        backgroundColor: '#444',
        padding: '10px',
        paddingTop: '0px',
        borderRadius: '5px',
        whiteSpace: 'pre-wrap',
        border: '1px solid white',
        color: '#fff',
        fontSize: '12px',
    },
    tooltipIcon: {
        marginLeft: '5px',
        color: '#007F6A'
    },
    printButton: {
        marginLeft: '10px',
        cursor: 'pointer',
        backgroundColor: '#003860',
        color: '#fff',
        border: 'none',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '12px',
        // textAlign: 'right'
    },
};