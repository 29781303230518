import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    // Navigate to the topics page
    navigate('/get-started');
  };

  return (
    <footer style={{ fontSize: '1.1rem', color: 'white', margin: '20px'}}>
      <p style={{fontSize: 'smaller', margin: '0px'}}>&copy; {new Date().getFullYear()} cheatsheet-plus-plus.com. All rights reserved.</p>
      <Link to="/terms-of-service" style={{fontSize: 'smaller'}}>Terms of Service</Link> | <Link to="privacy-policy" style={{fontSize: 'smaller'}}>Privacy Policy</Link>
    </footer>
  );
};

export default Footer;