import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import API_KEY from '../config';
import AdSense from './AdSense';
import { Helmet } from 'react-helmet';

import 'react-tooltip/dist/react-tooltip.css';
import '../styles/topicDetailsStyle';

const TopicHistory = () => {
    const { topic } = useParams();
    const [historyContent, setHistoryContent] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //console.log(`<<<<<<<<<<<<<< Calling getPageData with Topic: ${topic}`);
        fetchTopicHistory(topic);
    }, [topic]);

    const fetchTopicHistory = async (topic) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/topicHistories/${encodeURIComponent(topic)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${API_KEY}`
                }
            });          
            
            const data = await response.json();
            setHistoryContent((await data.content));
        } catch (error) {
            console.error('Error fetching topic history:', error);
        } finally {
            setLoading(false);
        }
    };
      
    const RenderHtmlContent = ({ htmlString }) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        
        return (
            <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    }; 
    
    return (
        <div style={styles.panel}>
            {/* <Helmet>
                
            </Helmet> */}
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div style={styles.container}>
                        <div>
                            <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                            <AdSense adClient="ca-pub-6060537356298204" adSlot="3832347185" adFormat="auto" />

                            <RenderHtmlContent htmlString={historyContent} />

                            <span style={{ fontSize: '10px', color: 'grey'}}>advertisement</span>
                            <AdSense adClient="ca-pub-6060537356298204" adSlot="2945337580" adFormat="auto" />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const styles = {
    panel: {
        flex: 1,
        backgroundColor: '#FFF',
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    container: {
        marginTop: '40px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '5px',
        fontFamily: 'Arial, sans-serif'
    }
}

export default TopicHistory;
