import React from 'react';
import { Helmet } from 'react-helmet';
import '../../styles/About.css';

const About = () => {
    return (
        <div className="container">
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6060537356298204"
                    crossOrigin="anonymous"></script>
            </Helmet>
            <header>
                <h1 style={{marginTop: '0px'}}>About Cheatsheet++</h1>
            </header>
            <main>
                <section style={{marginBottom: '2em'}}>
                    <h2>Our Mission</h2>
                    <p>
                        At Cheatsheet++, our mission is to empower students, professionals, and lifelong learners with sophisticated and accessible cheat sheets that are enhanced by artificial intelligence. We understand that navigating complex topics can be challenging, and our goal is to make learning more manageable and enjoyable. By distilling intricate concepts into clear, concise, and easily understandable formats, we aim to support you in your educational journey and help you retain critical information more effectively.
                    </p>
                    <p>
                        Our AI-driven cheat sheets are designed to be both comprehensive and user-friendly. We strive to continually update and improve our resources to ensure they meet the evolving needs of our users. Whether you're preparing for an exam, seeking to enhance your professional skills, or simply exploring new areas of interest, our mission is to provide you with the tools you need to succeed.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>Our Vision</h2>
                    <p>
                        We envision a world where knowledge is not only accessible but also comprehensible to everyone. Our vision is to become the leading resource for individuals seeking to quickly and effectively grasp new concepts and enhance their overall learning experience. We believe that by leveraging technology, we can break down barriers to education and make high-quality learning resources available to a global audience.
                    </p>
                    <p>
                        As we look to the future, we are committed to expanding our offerings and incorporating innovative features that cater to diverse learning needs. Our vision includes creating a dynamic learning environment where users can easily find and understand the information they need, thereby fostering a more informed and educated community.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>What We Offer</h2>
                    <p>
                        Cheatsheet++ provides a wide range of educational resources designed to support and enhance your learning experience. Our offerings include:
                    </p>
                    <ul>
                        <li><strong>Comprehensive Cheat Sheets:</strong> Detailed and well-organized cheat sheets covering a broad spectrum of topics. These resources are crafted to help you quickly understand and recall essential information.</li>
                        <li><strong>Historical Context:</strong> Contextual information that provides background and deeper insight into each topic. This helps users connect concepts and understand their relevance in a broader context.</li>
                        <li><strong>In-Depth Tutorials:</strong> Detailed tutorials that break down complex subtopics into manageable segments, aiding in mastery and deeper comprehension of the subject matter.</li>
                        <li><strong>Coming Soon:</strong> Additional resources, including interview questions and answers, designed to further support your educational and professional growth.</li>
                    </ul>
                    <p>
                        We are continually working on expanding our content and adding new features to better serve our users. Stay tuned for exciting updates and new resources that will be available in the near future.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>Our Story</h2>
                    <p>
                        Cheatsheet++ was founded by a team of dedicated learners and educators who identified a significant gap in the availability of high-quality, user-friendly educational resources. Recognizing the potential of artificial intelligence to revolutionize the way we learn, we set out to create a platform that combines the best of technology and education.
                    </p>
                    <p>
                        Our journey began with a shared vision to make learning more accessible and effective for everyone. By harnessing the power of AI, we have developed a platform that not only delivers valuable content but also adapts to the needs of our users. Our story is one of innovation, passion, and a commitment to improving the educational experience for learners around the world.
                    </p>
                </section>
                <section style={{marginBottom: '2em'}}>
                    <h2>Get in Touch</h2>
                    <p>
                        We value our users' feedback and are always eager to hear from you. If you have any questions, suggestions, or comments, please do not hesitate to reach out to us. We are here to assist you and ensure that your experience with Cheatsheet++ is both positive and productive.
                    </p>
                    <p>
                        You can contact us through our <a href="contact.html">contact page</a>, where you will find various ways to get in touch with our team. We look forward to hearing from you and appreciate your input as we continue to enhance our services.
                    </p>
                </section>
            </main>
        </div>
    );
};

export default About;
