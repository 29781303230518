import React, {useEffect, useState} from 'react';
import API_KEY from '../config';

const ExternalResources = ({topic}) => {

  const [resources, setResources] = useState([])

  useEffect(() => {
    const fetchResources = async () => {
      const resources = await fetchExtResources(topic);
      //console.log('resources', resources?.externalResources);
      setResources(resources?.externalResources);
    };

    fetchResources();
  }, [])
  
  const fetchExtResources = async (topic) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/extResources/${encodeURIComponent(topic)}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${API_KEY}`
        }
    });
      
    return await response.json();
  }

  return (
    <div>
      {/* <h4>resources: {resources.length}</h4> */}
      {resources?.length > 0 && resources.map((category, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <h4>{category.type}</h4>
          <ul>
            {category.resources.map((resource, idx) => (
              <li key={idx}>
                <a
                  href={resource.url || resource.amazonLink} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {resource.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ExternalResources
