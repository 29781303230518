import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './components/pages/Home';
import Topics from './components/Topics';
import TopicDetails from './components/TopicDetails';
import TopicHistory from './components/TopicHistory';
import SubtopicTutorial from './components/SubtopicTutorial'
import About from './components/pages/About';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import RelatedTopics from './components/RelatedTopics';

import InterviewQuestions from './components/InterviewQuestions'

// import Login from './components/pages/Login'
// import UserDashboard from './components/pages/UserDashboard'

import Navigation from './Navigation';
import Footer from './components/Footer';
import ReactGA from 'react-ga4';

import './App.css'; 

function App() {
  return (
    <Router>
      <AppContent />
      {/* <InterviewQuestions topic="java"/> */}
      {/* <RelatedTopics topic='java'/> */}
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  const user = false

  return (
    <div className="App-header background-div-hide topics-background2">
      <Navigation user={user}/>
      <div className="App">
        <Routes>
          <Route path="/" element={<Topics />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/topics" element={<Topics />} />
          <Route path="/topics/:topic" element={<TopicDetails />} />
          <Route path="/topicHistories/:topic" element={<TopicHistory />} />
          <Route path="/subtopicTutorials/:topic_subtopic" element={<SubtopicTutorial />} />

          {/* <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={ user ? <UserDashboard/> : <Navigate to="/login"/>} /> */}
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
