import React, { useState, useEffect } from 'react';
import API_KEY from '../config';

const RelatedTopics = ({ topic = 'kubernetes' }) => {
  const [relatedTopics, setRelatedTopics] = useState([]);

  useEffect(() => {
    const fetchRelatedTopics = async (topic) => {
      const topics = await getRelatedTopics(topic)
      //console.log('topics', topics)
      setRelatedTopics(topics.relatedTopics.filter((t) => t !== topic));
    }

    fetchRelatedTopics(topic)
  }, [topic]);

  const getRelatedTopics = async (topic) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_URL}/api/relatedTopics/${encodeURIComponent(topic)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${API_KEY}`
            }
        });

        //console.log('response', response)

        return await response.json();
    } catch (error) {
        console.error('Error fetching related topics:', error);
    }      
}

  return (
    <div style={styles.card}>
      <div style={styles.header}><b>related to {topic}...</b></div>
      <div style={styles.container}>
        {relatedTopics.slice(0, 12).map((topic, index) => (
          <a
            key={index}
            href={`/topics/${encodeURIComponent(topic)}`}
            style={styles.link}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            {topic.replace(/-/g, ' ')} {/* Replace hyphens with spaces for readability */}
          </a>
        ))}
      </div>
      <hr/>
    </div>
  );
};

const styles = {
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    // padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    // maxWidth: '800px',
    // margin: '20px auto',
    textAlign: 'center',
  },
  header: {
    fontSize: '12px',
    textAlign: 'left',
    // marginBottom: '20px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // padding: '10px',
    // paddingTop: '0px',
    // gap: '10px',
  },
  link: {
    backgroundColor: '#eee',
    padding: '2px 6px',
    textDecoration: 'none',
    color: 'blue',
    borderRadius: '3px',
    // minWidth: '150px',
    textAlign: 'center',
    //flex: '1 0 15%', // Makes the links responsive, with a minimum width
    // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.5)',
    transition: 'transform 0.9s ease-in-out',
    fontSize: 'small',
    margin: '1px'
  },
};

export default RelatedTopics;
